import firebase from 'firebase/compat/app'
import { setDocFirestore } from '@/services/firebaseWrite'

export const getAllProcedures = async ($fire: any) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .get()
}

export const getLastProcedure = async ($fire: any) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .limit(1)
    .get()
}

export const getProcedure = async ($fire: any, id: string) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .doc(id)
    .get()
}

export const setProcedure = async ($fire: any, data: any) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .add(data)
}

export const updateProcedure = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('procedures')
  //   .doc(id)
  //   .set(data, { merge: true })
  return await setDocFirestore(
    $fire,
    `/modules/ventanillaDigital/procedures/${id}`,
    { ...body },
    true
  )
}

export const saveProcedureRating = async (
  $fire: any,
  path: string,
  body: any
) => {
  const data = { ...body }
  data.createdAt = firebase.firestore.Timestamp.now()
  return await $fire.firestore.collection(`${path}/ratings`).add(data)
}
