import { getAllProcedures } from '@/services/Procedure'

export const state = () => ({
  procedures: [],
  settings: {},
})

export const mutations = {
  SET_PROCEDURES(state, procedures) {
    state.procedures = [...procedures]
  },
  SET_SETTINGS(state, settings) {
    state.settings = Object.assign({}, { ...settings })
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllProcedures(this.$fire)
      const procedures = []
      let settings = {}
      if (!result.empty) {
        result.forEach((doc) => {
          if (doc.id !== 'settings')
            procedures.push({ id: doc.id, path: doc.ref.path, ...doc.data() })
          else {
            settings = { ...doc.data() }
          }
        })
      }
      commit('SET_SETTINGS', settings)
      commit('SET_PROCEDURES', procedures)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getProcedures(state) {
    return state.procedures
  },
  getActiveProcedures(state) {
    const activeProcedures = state.procedures.filter(
      (procedure) => procedure.active === true
    )
    return activeProcedures
  },
  getSettingsProcedures(state) {
    return state.settings
  },
}
