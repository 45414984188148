export default async (context) => {
  context.store.dispatch('setHost')
  const promises = [
    context.store.dispatch('authconfig/get'),
    context.store.dispatch('brand/get'),
    context.store.dispatch('filters/get'),
    context.store.dispatch('modules/get'),
    context.store.dispatch('setting/get'),
    context.store.dispatch('catalogs/get'),
    context.store.dispatch('procedures/get'),
    context.store.dispatch('landingtext/get'),
    context.store.dispatch('certsConfig/get'),
    context.store.dispatch('collaborator/get'),
    context.store.dispatch('roles/get'),
    context.store.dispatch('inspectorsConcepts/getConfiguration'),
  ]
  await Promise.all(promises)
}
