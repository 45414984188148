
/* eslint-disable no-undef */
/* eslint-disable vue/prop-name-casing */

import { Loader } from '@googlemaps/js-api-loader'
import { filter, forEach } from 'lodash'

export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    updateMap: {
      // type: String,
      type: Boolean,
    },
    resetMap: {
      // type: String,
      type: Boolean,
    },
    typeOfMap: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    claveUso: {
      type: Object,
    },
    cp: {
      type: [Number],
      default: null,
    },
    state: {
      type: String,
      default: null,
    },
    city: {
      type: String,
      default: null,
    },
    neighborhood: {
      type: String,
      default: null,
    },
    street: {
      type: String,
      default: null,
    },
    outdoorNumber: {
      type: [String],
      default: null,
    },
    interiorNumber: {
      type: [String],
      default: null,
    },
    reference: {
      type: String,
      default: null,
    },
    lat: {
      type: Number,
      default: 25.6704575,
    },
    lng: {
      type: Number,
      default: -100.3109926,
    },
    configMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputId: 'input-' + Math.random(),
      mapId: 'map-' + Math.random(),
      map: null,
      marker: null,
      geocoder: null,
      autocomplete: null,
      search: null,
      polygon: null,
      polygonId: null,
      center: {
        lat: this.lat ? this.lat : 25.6704575,
        lng: this.lng ? this.lng : -100.3109926,
      },
      rules: [(v) => !!v || this.$t('fieldRequired')],
      wmsLayer: null,
      panel: 0,
      dialog: false,
    }
  },
  watch: {
    lat(val, old) {
      // eslint-disable-next-line no-console
      console.log('watch lat')
      // eslint-disable-next-line no-console
      console.log('val', val, 'old', old)
      // @todo Validate with @Chepe
      if (val !== null) {
        this.center = {
          lat: this.lat,
          lng: this.lng,
        }
      }
    },
    resetMap(val) {
      if (val !== null) {
        this.center = {
          lat: 25.6704575,
          lng: -100.3109926,
        }
        this.initMap()
      }
    },
    updateMap: {
      handler(val, old) {
        // eslint-disable-next-line no-console
        console.log('watch updatemap')
        // eslint-disable-next-line no-console
        console.log('updateMap val', val, 'old', old)
        if (val !== null) {
          this.addPolygon()
        }
      },
      // force eager callback execution
      // immediate: false
    },
    async configMap() {
      this.$store.dispatch('loaderFull/open')
      await this.initMap()
      await this.addPolygon()
      this.$store.dispatch('loaderFull/close')
    },
  },
  beforeMount() {
    this.mapId = 'map-' + Math.random()
  },
  async mounted() {
    // this.updateMap = !this.updateMap

    await this.initMap()
  },
  methods: {
    async initMap() {
      this.$store.dispatch('loaderFull/open')
      const self = this
      await new Loader({
        apiKey: `${process.env.GOOGLE_API_KEY}`,
        version: 'weekly',
        libraries: ['places'],
      })
        .load()
        .then((google) => {
          if (document.getElementById(this.mapId)) {
            // Definir la URL base del servicio WMS
            this.map = null
            this.map = new google.maps.Map(
              document.getElementById(this.mapId),
              {
                center: this.center,
                zoom: 15,
                mapTypeId:
                  this.configMap.basemap !== 'ortofoto'
                    ? this.configMap.basemap
                    : 'terrain',
              }
            )

            this.marker = new google.maps.Marker({
              map: this.map,
              draggable: !this.disabled, // Editable view
              position: this.center,
            })

            this.geocoder = new google.maps.Geocoder()
            const input = document.getElementById(this.inputId)
            this.autocomplete = new google.maps.places.Autocomplete(input, {
              fields: ['formatted_address', 'geometry', 'name'],
            })
            this.marker.addListener('dragend', this.coordinates)
            this.autocomplete.bindTo('bounds', this.map)
            this.autocomplete.addListener('place_changed', this.placeChanged)

            // Escuchar el evento 'tilesloaded' para detectar cuándo el mapa ha cargado completamente
            google.maps.event.addListenerOnce(
              self.map,
              'tilesloaded',
              async function () {
                if (self.configMap.basemap === 'ortofoto') {
                  await self.loadOrtofoto()
                }
                // El mapa ha cargado completamente
                self.updateWmsLayer()

                // Escuchar el evento de cambio de límites del mapa
                google.maps.event.addListener(
                  self.map,
                  'bounds_changed',
                  async function () {
                    self.$store.dispatch('loaderFull/open')
                    // Actualizar la capa WMS cuando los límites del mapa cambien
                    await self.updateWmsLayer()
                    self.$store.dispatch('loaderFull/close')
                  }
                )
              }
            )
          }
        })
        .catch((error) => {
          throw new Error(error)
        })
      this.$store.dispatch('loaderFull/close')
    },
    addPolygon() {
      const vm = this
      if (this.claveUso) {
        if (this.polygon !== null) {
          this.map.data?.forEach(function (feature) {
            if (feature.getId() === vm.polygonId) {
              vm.map.data.remove(feature)
            }
          })
        }
        this.polygon = this.map.data?.addGeoJson(this.claveUso, {
          idPropertyName: 'ecarto_id',
        })
        this.$emit('setPolygon', this.claveUso.features[0].geometry)

        const geoJsonStyle2 = {
          // fillColor: 'blue', // Color de relleno
          fillOpacity: this.configMap.basemap === 'ortofoto' ? 0 : 0.6, // Opacidad del relleno
          strokeColor:
            this.configMap.basemap === 'ortofoto' ? 'white' : 'black', // Color del borde
          strokeWeight: 3, // Grosor del borde
        }

        // Aplicar el estilo a los polígonos
        this.map.data.setStyle(geoJsonStyle2)

        this.polygonId = this.claveUso.features[0].properties.ecarto_id

        this.map.setZoom(18)
        this.map.setCenter({ lat: this.lat, lng: this.lng })
        this.marker.setPosition({ lat: this.lat, lng: this.lng })
        if (!this.typeOfMap) {
          setTimeout(() => {
            this.coordinates({ lat: this.lat, lng: this.lng }, 'latlng')
          }, 500)
        }
      }
    },
    placeChanged() {
      let address = ''
      const self = this
      const place = this.autocomplete.getPlace()

      if (place) {
        if (place.formatted_address) {
          address = place.formatted_address
        } else {
          address = place.name
        }

        this.geocoder.geocode({ address }, function (results, status) {
          if (status !== 'OK') {
            window.alert('Geocoder failed due to: ' + status)
            return
          }

          self.map.setZoom(15)
          self.map.setCenter(results[0].geometry.location)
          self.marker.setPosition(results[0].geometry.location)
          self.coordinates(results[0].geometry.location, 'autocomplete')
        })
      }
    },
    coordinates(event, from) {
      const self = this
      let location

      if (from === 'autocomplete') {
        this.$emit('update:lat', event.lat())
        this.$emit('update:lng', event.lng())
        this.$emit('handlerLocation', this.location)
        location = event
      } else if (from === 'latlng') {
        location = event
      } else {
        this.$emit('update:lat', event.latLng.lat())
        this.$emit('update:lng', event.latLng.lng())
        this.$emit('handlerLocation', this.location)
        location = event.latLng
      }

      this.geocoder.geocode({ location }, function (results) {
        const filteredResults = filter(results, function (result) {
          return (
            result.types[0] === 'street_address' || result.types[0] === 'route'
          )
        })
        if (filteredResults.length > 0) {
          forEach(filteredResults[0].address_components, function (el) {
            if (el.types.includes('route')) {
              self.$emit('update:street', el.long_name)
            }
            if (el.types.includes('sublocality')) {
              self.$emit('update:neighborhood', el.long_name)
            }
            if (el.types.includes('street_number')) {
              self.$emit('update:outdoorNumber', el.long_name)
            }
            if (el.types.includes('postal_code')) {
              self.$emit('update:cp', parseInt(el.long_name))
            }
            if (el.types.includes('administrative_area_level_1')) {
              self.$emit('update:state', el.long_name)
            }
            if (el.types.includes('locality')) {
              self.$emit('update:city', el.long_name)
            }
          })
        }
      })
    },

    // Función para actualizar la capa WMS con nuevos límites
    updateWmsLayer() {
      const self = this
      const bounds = this.map.getBounds()
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()

      const bbox = sw.lng() + ',' + sw.lat() + ',' + ne.lng() + ',' + ne.lat()
      const mapContainer = document.getElementById(this.mapId)
      const mapWidth = mapContainer.offsetWidth
      const mapHeight = mapContainer.offsetHeight

      // Limpiar todas las capas existentes antes de añadir nuevas
      if (self.wmsLayers) {
        self.wmsLayers.forEach((layer) => {
          layer.setMap(null)
        })
      }
      self.wmsLayers = []

      const layers = this.configMap.selectedMap.data.maplayers
      layers?.forEach((layer) => {
        if (layer.show === true) {
          const nameLayer = layer?.dataset?.alternate || layer.current_style

          const wmsUrl =
            'https://mide.monterrey.gob.mx/geoserver/ows?' +
            'service=WMS&' +
            'request=GetMap&' +
            'layers=' +
            nameLayer +
            '&styles=&' +
            'srs=EPSG:4326&' +
            'format=image/png&' +
            'width=' +
            mapWidth +
            '&height=' +
            mapHeight +
            '&bbox=' +
            bbox

          const opacity = layer.opacity === 0 ? 0 : layer.opacity / 100
          const wmsLayer = new google.maps.GroundOverlay(wmsUrl, bounds, {
            opacity,
          })
          wmsLayer.setMap(self.map)
          self.wmsLayers.push(wmsLayer)
        }
      })
    },
    loadOrtofoto() {
      const self = this
      const xyzUrl =
        'https://engine.ec.appsmty.gob.mx/stiles/5ba54d62469b11eebe7f00155d649203/{z}/{x}/{y}.jpg'
      const xyzOptions = {
        getTileUrl(coord, zoom) {
          const y = (1 << zoom) - coord.y - 1
          const url = xyzUrl
            .replace('{x}', coord.x)
            .replace('{y}', y)
            .replace('{z}', zoom)
          return url
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 18,
        minZoom: 0,
        name: 'XYZ Layer', // Nombre opcional de la capa
      }

      // Crear la capa personalizada
      const xyzLayer = new google.maps.ImageMapType(xyzOptions)

      // Agregar la capa XYZ al mapa
      self.map.overlayMapTypes.push(xyzLayer)
    },
  },
}
