import { setDocFirestore } from '@/services/firebaseWrite'

export const signUp = async ($fire: any, { email, password }: any) => {
  return await $fire.auth.createUserWithEmailAndPassword(email, password)
}

export const signInData = async ($fire: any, { credential }: any) => {
  return await $fire.auth.signInAndRetrieveDataWithCredential(credential)
}

export const signInCustomToken = async ($fire: any, { credential }: any) => {
  return await $fire.auth.signInWithCustomToken(credential)
}

export const updateUserAuth = async ($fire: any, user: any) => {
  const currentUser = $fire.auth.currentUser
  return await currentUser.updateProfile(user)
}

export const reauthenticate = async ($fire: any, credential: any) => {
  const currentUser = $fire.auth.currentUser
  return await currentUser.reauthenticateWithCredential(credential)
}

export const updatePasswordAuth = async (
  $fire: any,
  confirmNewPassword: string
) => {
  const currentUser = $fire.auth.currentUser
  return await currentUser.updatePassword(confirmNewPassword)
}

export const updateEmailAuth = async ($fire: any, newEmail: string) => {
  const currentUser = $fire.auth.currentUser
  return await currentUser.updateEmail(newEmail)
}

export const getCredentialToAuth = async (
  $fireModule: any,
  body: { email: any; currentPassword: any }
) => {
  return await $fireModule.auth.EmailAuthProvider.credential(
    body.email,
    body.currentPassword
  )
}

export const logIn = async ($fire: any, { email, password }: any) => {
  return await $fire.auth.signInWithEmailAndPassword(email, password)
}

export const logOut = async ($fire: any) => {
  return await $fire.auth.signOut()
}

export const getDependencies = async ($fire: any) => {
  const dependencies: any[] = []
  await $fire.firestore
    .collection('catalogs')
    .where('slug', '==', 'dependencias')
    .get()
    .then(async (querySnapshot: any) => {
      if (querySnapshot.docs.length > 0) {
        const catalogId = querySnapshot.docs[0].id

        await $fire.firestore
          .collection(`catalogs/${catalogId}/terms`)
          .get()
          .then((queryTerms: any) => {
            if (queryTerms.docs.length > 0) {
              queryTerms.docs.forEach((doc: any) => {
                dependencies.push({ ...doc.data() })
              })
            }
          })
      }
    })
  return dependencies
}

export const getSubdependencies = async ($fire: any, dependencyId: any) => {
  const subdependencies: any[] = []
  let catalogId = ''
  await $fire.firestore
    .collection('catalogs')
    .where('slug', '==', 'dependencias')
    .get()
    .then((querySnapshot: any) => {
      if (querySnapshot.docs.length > 0) {
        catalogId = querySnapshot.docs[0].id
      }
    })

  if (catalogId) {
    await $fire.firestore
      .collection(`catalogs/${catalogId}/terms/${dependencyId}/terms`)
      .get()
      .then((queryTerms: any) => {
        if (queryTerms.docs.length > 0) {
          queryTerms.docs.forEach((doc: any) => {
            subdependencies.push({ ...doc.data() })
          })
        }
      })
  }
  return subdependencies
}

export const savePageBuilderData = async ($fire: any, data: any) => {
  // return await $fire.firestore.collection('pageBuilder').doc().set(data)
  return await setDocFirestore($fire, `pageBuilder`, { ...data }, false)
}

// Storage
export const saveFile = async ($fire: any, file: any, folder: string) => {
  const fileRef = $fire.storage().ref().child(`${folder}/${file.name}`)
  await fileRef.put(file)
}
