import moment from 'moment'
import * as _ from 'lodash'
import { setDocFirestore } from '@/services/firebaseWrite'

export const setCatalogs = async ($fire: any, docs: [], collection: string) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: any) => {
    doc.created = moment().format()

    const docRef = $fire.firestore.collection(collection).doc()
    batch.set(docRef, doc)
  })
  return await batch.commit()
}

export const getProvinces = async ($fire: any) => {
  return await $fire.firestore.collection('provinces').get()
}

export const getNationalities = async ($fire: any) => {
  return await $fire.firestore.collection('nationalities').get()
}

export const getActivities = async ($fire: any) => {
  return await $fire.firestore.collection('activities').get()
}

export const getDepartmentsByProvince = async (
  $fire: any,
  codigoProvincia: string
) => {
  return await $fire.firestore
    .collection('departments')
    .where('code_provincia', '==', codigoProvincia)
    .get()
}

export const getLocalitiesByDepartment = async (
  $fire: any,
  codigoDepartamento: string,
  codigoProvincia: string
) => {
  return await $fire.firestore
    .collection('localities')
    .where('code_departamento', '==', codigoDepartamento)
    .where('code_provincia', '==', codigoProvincia)
    .get()
}

export const getExemptTypes = () => {
  const jsonFile = require('@/static/js/catalogs/tipo_exento.json')

  return jsonFile.tipo_exento
}

export const getCivilStatus = () => {
  const jsonFile = require('@/static/js/catalogs/estado_civil.json')

  return jsonFile.estado_civil
}

export const getAdressTypes = () => {
  const jsonFile = require('@/static/js/catalogs/tipo_domicilio.json')

  return jsonFile.tipo_domicilio
}

export const getIvaSituation = () => {
  const jsonFile = require('@/static/js/catalogs/situacion_iva.json')

  return jsonFile.situacion_iva
}

export const getActivityDescription = async (
  $fire: any,
  codeActividad: string
) => {
  return await $fire.firestore
    .collection('activities')
    .where('code_actividad', '==', codeActividad)
    .get()
}

export const getCatalogs = async ($fire: any) => {
  return await $fire.firestore.collection('catalogs').get()
}

export const getModules = async ($fire: any) => {
  return await $fire.firestore.collection('modules').get()
}

export const termsList = async ($fire: any, catalogID: string) => {
  return await $fire.firestore.collection(catalogID).get()
}

export const gatalog = async ($fire: any, catalogID: string) => {
  return await $fire.firestore.doc(catalogID).get()
}

export const updatesubCataloglevels = async (
  $fire: any,
  id: string,
  body: any
) => {
  // const data = { ...body }
  // return await $fire.firestore.doc(id).set(
  //   {
  //     ...data,
  //   }
  //   // { merge: true }
  // )
  return await setDocFirestore($fire, id, { ...body }, false)
}

export const saveCatalog = async ($fire: any, catalog: any) => {
  const newCatalog = await $fire.firestore.collection('catalogs').doc()

  // newCatalog.set({
  //   ...catalog,
  //   id: newCatalog.id,
  //   path: newCatalog.path,
  // })
  await setDocFirestore(
    $fire,
    `catalogs/${newCatalog.id}`,
    { ...catalog, id: newCatalog.id, path: newCatalog.path },
    false
  )

  return { ...catalog, id: newCatalog.id, path: newCatalog.path }
  // return await $fire.firestore.collection('catalogs').add(catalog)
}

export const saveSublevel = async (
  $fire: any,
  collection: any,
  catalog: any
) => {
  const newSublevel = await $fire.firestore.collection(collection).doc()

  await setDocFirestore(
    $fire,
    `${collection}/${newSublevel.id}`,
    { ...catalog, id: newSublevel.id, path: newSublevel.path },
    false
  )

  return { ...catalog, id: newSublevel.id, path: newSublevel.path }
}

export const updateCatalog = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('catalogs')
  //   .doc(id)
  //   .set(
  //     {
  //       ...data,
  //     },
  //     { merge: true }
  //   )
  return await setDocFirestore($fire, `catalogs/${id}`, { ...body }, true)
}

export const updateCatalogModules = async (
  $fire: any,
  id: string,
  body: any,
  $store: any
) => {
  const currentCatalog = (await gatalog($fire, `catalogs/${id}`)).data()
  let modules = currentCatalog?.modules || []
  if (modules.length > 0) {
    modules = modules.map((moduleFromFb: any) => {
      return moduleFromFb.id === body.id ? { ...body } : { ...moduleFromFb }
    })
    modules.push(body)
  } else modules.push(body)
  modules = _.uniqWith(modules, _.isEqual)
  let modulesId = modules.map((module: any) => module.id)
  modulesId = _.uniqWith(modulesId, _.isEqual)
  $store.dispatch('catalogs/updateCatalog', {
    ...currentCatalog,
    modulesId,
    modules: [...modules],
  })
  // return await $fire.firestore
  //   .collection('catalogs')
  //   .doc(id)
  //   .set(
  //     {
  //       modules: [...modules],
  //       modulesId,
  //     },
  //     { merge: true }
  //   )
  return await setDocFirestore(
    $fire,
    `catalogs/${id}`,
    { modules: [...modules], modulesId },
    true
  )
}

export const updateCatalogModulesEmpty = async (
  $fire: any,
  catalogId: string,
  moduleToRemoveId: string,
  $store: any
) => {
  const currentCatalog = (await gatalog($fire, `catalogs/${catalogId}`)).data()
  let modules = currentCatalog?.modules || []
  modules = modules.filter((module: any) => module.id !== moduleToRemoveId)
  const modulesId = modules.map((module: any) => module.id)
  $store.dispatch('catalogs/updateCatalog', {
    ...currentCatalog,
    modulesId,
    modules: [...modules],
  })
  return await $fire.firestore.collection('catalogs').doc(catalogId).set(
    {
      modules,
      modulesId,
    },
    { merge: true }
  )
}

export const deleteCatalog = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('catalogs').doc(id).delete()
}
