import Vue from 'vue'

export default Vue.extend({
  name: 'GrecaptchaMixin',
  data() {
    return {
      grecaptchaToken: process.env.GRECAPTCHA_TOKEN || '',
    }
  },
  methods: {
    generateGrecaptchaToken(): any {
      return this.$store.state.grecaptcha.enterprise
        .execute(this.grecaptchaToken, {
          action: 'verify',
        })
        .then((token: string) => {
          // eslint-disable-next-line no-console
          return token
        })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.log('error', error)
          return error
        })
    },
  },
})
