
import Vue from 'vue'
import { mapGetters } from 'vuex'
import UserIcon from '@/components/icons/sidebar/UserIcon.vue'
import ModuleIcon from '@/components/icons/sidebar/ModuleIcon.vue'
import CatalogosIcon from '@/components/icons/sidebar/CatalogosIcon.vue'
import OSCityIcon from '@/components/icons/sidebar/OSCityIcon.vue'
import FilterIcon from '@/components/icons/sidebar/FilterIcon.vue'
import Logo from '@/components/public/Logo.vue'
import ConfiguracionIcon from '@/components/icons/sidebar/ConfiguracionIcon.vue'
import { logOut } from '@/services/Firebase'
import InspectorIcon from '@/components/icons/formFill/InspectorIcon.vue'
import NotificatorIcon from '@/components/icons/formFill/NotificatorIcon.vue'
import CardPayIcon from '@/components/icons/payments/CardPayIcon.vue'
import CalendarioIcon from '@/components/icons/citas/CalendarioIcon.vue'
import EstadisticasIcon from '@/components/icons/sidebar/EstadisticasIcon.vue'
// import HerramientasIcon from '@/components/icons/sidebar/HerramientasIcon.vue'
// import EditarAparienciaIcon from '@/components/icons/sidebar/EditarAparienciaIcon.vue'
// import TemplatesIcon from '@/components/icons/sidebar/TemplatesIcon.vue'
// import BandejaEntradaIcon from '@/components/icons/sidebar/BandejaEntradaIcon.vue'

export default Vue.extend({
  name: 'SidebarFuncionario',
  components: {
    UserIcon,
    ModuleIcon,
    InspectorIcon,
    NotificatorIcon,
    CardPayIcon,
    CalendarioIcon,
    CatalogosIcon,
    OSCityIcon,
    FilterIcon,
    ConfiguracionIcon,
    Logo,
    EstadisticasIcon,
    // HerramientasIcon,
    // EditarAparienciaIcon,
    // TemplatesIcon,
    // BandejaEntradaIcon
  },
  props: {
    drawerSidebar: {
      type: Boolean,
      default: false,
    },
    typeProfile: {
      type: String,
      default: '',
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ventanillaDigitalPages: [
        {
          type: 'procBuilder',
          link: '/admin/modules/ventanillaDigital/procedures?activeTab=0',
          title: this.$t('sidebar.procBuilder'),
          allowedRoles: ['superadmin', 'admin'],
          module: 'ventanillaDigital',
        },
        {
          type: 'procList',
          link: '/admin/modules/ventanillaDigital/procedures?activeTab=1',
          title: this.$t('sidebar.procList'),
          allowedRoles: ['superadmin', 'admin', 'editor'],
          module: 'ventanillaDigital',
        },
        {
          type: 'procInbox',
          link: '/admin/modules/ventanillaDigital/procedures?activeTab=2',
          title: this.$t('sidebar.procInbox'),
          allowedRoles: [
            'superadmin',
            'admin',
            'editor',
            'operator',
            'visualizer',
          ],
          module: 'ventanillaDigital',
        },
        {
          type: 'procConfiguration',
          link: '/admin/modules/ventanillaDigital',
          title: this.$t('sidebar.configuration'),
          allowedRoles: ['superadmin', 'admin'],
          module: 'ventanillaDigital',
        },
      ],
      inspectorPages: [
        {
          type: 'inspInbox',
          link: '/admin/inspectores?activeTab=0',
          title: this.$t('sidebar.procInbox'),
          allowedRoles: [
            'superadmin',
            'admin',
            'editor',
            'operator',
            'visualizer',
          ],
          module: 'inspectors',
        },
        {
          type: 'inspAssignIbx',
          link: '/admin/inspectores?activeTab=1',
          title: this.$t('sidebar.inspAssignIbx'),
          allowedRoles: ['superadmin', 'admin', 'editor', 'visualizer'],
          module: 'inspectors',
        },
        {
          type: 'inspConfiguration',
          link: '/admin/inspectores/configuration',
          title: this.$t('sidebar.configuration'),
          allowedRoles: ['superadmin', 'admin'],
          module: 'inspectors',
        },
      ],
      notificationPages: [
        {
          type: 'notInbox',
          link: '/admin/preventiveDelivery?activeTab=0',
          title: this.$t('sidebar.procInbox'),
          allowedRoles: [
            'superadmin',
            'admin',
            'editor',
            'operator',
            'visualizer',
          ],
          module: 'notification',
        },
        {
          type: 'notList',
          link: '/admin/preventiveDelivery?activeTab=1',
          title: this.$t('sidebar.inspAssignIbx'),
          allowedRoles: ['superadmin', 'admin', 'editor', 'visualizer'],
          module: 'notification',
        },
        {
          type: 'notConfiguration',
          link: '/admin/preventiveDelivery/configuration',
          title: this.$t('sidebar.configuration'),
          allowedRoles: ['superadmin', 'admin'],
          module: 'notification',
        },
      ],
      paymentPages: [
        {
          type: 'payList',
          link: '/admin/payments',
          title: this.$t('sidebar.payList'),
          allowedRoles: ['superadmin', 'tesorero', 'visualizer'],
          module: 'payments',
        },
        {
          type: 'payConfiguration',
          link: '/admin/payments/configuration',
          title: this.$t('sidebar.configuration'),
          allowedRoles: ['superadmin', 'admin'],
          module: 'payments',
        },
      ],
      apptPages: [
        {
          type: 'apptInbox',
          link: '/admin/citas?activeTab=0',
          title: this.$t('sidebar.procInbox'),
          allowedRoles: [
            'superadmin',
            'admin',
            'editor',
            'operator',
            'visualizer',
          ],
          module: 'appointments',
        },
        {
          type: 'apptList',
          link: '/admin/citas?activeTab=1',
          title: this.$t('sidebar.inspAssignIbx'),
          allowedRoles: ['superadmin', 'admin', 'editor', 'visualizer'],
          module: 'appointments',
        },
        {
          type: 'apptConfiguration',
          link: '/admin/citas/configuration',
          title: this.$t('sidebar.configuration'),
          allowedRoles: ['superadmin', 'admin'],
          module: 'appointments',
        },
      ],
      userPages: [
        {
          type: 'usrCitizen',
          link: '/admin/users/configuration?activeTab=0',
          title: this.$t('sidebar.citizens'),
          allowedRoles: ['superadmin'],
        },
        {
          type: 'usrInstitutional',
          link: '/admin/users/configuration?activeTab=1',
          title: this.$t('sidebar.institutional'),
          allowedRoles: ['superadmin'],
        },
        {
          type: 'usrFunctionary',
          link: '/admin/users/configuration?activeTab=2',
          title: this.$t('sidebar.functionary'),
          allowedRoles: ['superadmin'],
        },
        {
          type: 'usrVisualizer',
          link: '/admin/users/configuration?activeTab=3',
          title: this.$t('sidebar.visualizer'),
          allowedRoles: ['superadmin'],
        },
        {
          type: 'usrRoles',
          link: '/admin/users/configuration?activeTab=4',
          title: this.$t('sidebar.roles'),
          allowedRoles: ['superadmin'],
        },
        {
          type: 'usrConfiguration',
          link: '/admin/users/configuration?activeTab=5',
          title: this.$t('sidebar.configuration'),
          allowedRoles: ['superadmin'],
        },
      ],
      drawer: !this.$vuetify.breakpoint.smAndDown,
      loading: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
      logoutLoading: false,
      userIsRegistered: false,
      userHavePublicAddress: false,
      lista: 0,
      isMTYInstance:
        process.env.FB_PROJECT_ID === 'os-sandbox-mty' ||
        process.env.FB_PROJECT_ID === 'os-monterrey',
      closeVentanillaDigital: false,
      closeInspectors: false,
      closeNotification: false,
      closePayment: false,
      closeAppointments: false,
      closeUsers: false,
      oldValues: {
        closeVentanillaDigital: false,
        closeInspectors: false,
        closeNotification: false,
        closePayment: false,
        closeAppointments: false,
        closeUsers: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      editedTexts: 'landingtext/landingtext',
      activeProcedures: 'procedures/getActiveProcedures',
      activeModules: 'modules/getActiveModules',
      role: 'users/getRole',
      inspectorRole: 'users/getInspectorsRole',
      prevDeliveryRole: 'users/getPrevDeliveryRole',
      paymentsRole: 'users/getPaymentsRole',
      apptsRole: 'users/getAppointmentsRole',
      auxRole: 'users/getAuxRole',
      activePrivateProcedures: 'setting/activePrivateProcedures',
      involvedModules: 'users/getInvolvedModules',
    }),
    userName() {
      let userName = ''
      if (this.user?.first_name && this.user?.last_name) {
        userName =
          this.user?.first_name?.split(' ')[0] +
          ' ' +
          this.user?.last_name +
          (this.user?.last_name2 ? ' ' + this.user?.last_name2 : '')
        if (userName.length > 20) {
          userName = userName.slice(0, 18) + '...'
        }
      } else if (this.user?.email) {
        const leftPart = this.user?.email.split('@')[0].slice(0, 3)
        const rightPart = this.user?.email.split('@')[1].slice(0, 5)
        userName = `${leftPart}...@${rightPart}...`
      }
      return userName
    },
    noModules() {
      return !(this.user?.availableModules?.length > 0)
    },
  },
  watch: {
    lista(val) {
      if (val === 0) {
        this.$emit('expandirSidebar')
      }
    },
    drawerSidebar(val) {
      this.drawer = val
    },
    user(val) {
      if (val.uid) {
        this.verifyUserRegistration(val.uid)
      }
    },
    mini(val) {
      if (val) {
        Object.keys(this.oldValues).forEach((key) => {
          this.oldValues[key] = false
        })
        this.closeVentanillaDigital = false
        this.closeInspectors = false
        this.closeNotification = false
        this.closePayment = false
        this.closeAppointments = false
        this.closeUsers = false
      } else {
        const lastActive = Object.keys(this.oldValues).find(
          (val) => this.oldValues[val] === true
        )
        if (lastActive) {
          this[lastActive] = true
        } else if (
          this.$route.fullPath.includes('admin/modules/ventanillaDigital')
        ) {
          this.closeVentanillaDigital = true
        } else if (this.$route.fullPath.includes('admin/inspectores')) {
          this.closeInspectors = true
        } else if (this.$route.fullPath.includes('admin/preventiveDelivery')) {
          this.closeNotification = true
        } else if (this.$route.fullPath.includes('admin/payments')) {
          this.closePayment = true
        } else if (this.$route.fullPath.includes('admin/citas')) {
          this.closeAppointments = true
        } else if (this.$route.fullPath.includes('admin/users')) {
          this.closeUsers = true
        }
      }
    },
  },
  mounted() {
    this.onResize()
  },
  methods: {
    closeSubmenus() {
      Object.keys(this.oldValues).forEach((key) => {
        this.oldValues[key] = false
      })
      this.closeVentanillaDigital = false
      this.closeInspectors = false
      this.closeNotification = false
      this.closePayment = false
      this.closeAppointments = false
      this.closeUsers = false
    },
    changeLastActive(newActive) {
      Object.keys(this.oldValues).forEach((key) => {
        this.oldValues[key] = false
      })
      this[newActive] = true
      this.oldValues[newActive] = true
    },
    checkIfRoleIsAllowed(val) {
      const moduleId = val.module
      let valid = false
      if (moduleId === 'ventanillaDigital') {
        if (
          val.allowedRoles.includes(this.role) ||
          val.allowedRoles.includes(this.auxRole)
        )
          valid = true
      } else if (moduleId === 'inspectors') {
        if (
          (val.allowedRoles.includes(this.inspectorRole) &&
            this.auxRole === 'officer') ||
          this.role === 'superadmin' ||
          val.allowedRoles.includes(this.auxRole)
        )
          valid = true
      } else if (moduleId === 'notification') {
        if (
          (val.allowedRoles.includes(this.prevDeliveryRole) &&
            this.auxRole === 'officer') ||
          this.role === 'superadmin' ||
          val.allowedRoles.includes(this.auxRole)
        )
          valid = true
      } else if (moduleId === 'payments') {
        if (
          (val.allowedRoles.includes(this.paymentsRole) &&
            this.auxRole === 'officer') ||
          this.role === 'superadmin' ||
          val.allowedRoles.includes(this.auxRole)
        )
          valid = true
      } else if (moduleId === 'payments') {
        if (
          (val.allowedRoles.includes(this.paymentsRole) &&
            this.auxRole === 'officer') ||
          this.role === 'superadmin' ||
          val.allowedRoles.includes(this.auxRole)
        )
          valid = true
      } else if (moduleId === 'appointments') {
        if (
          (val.allowedRoles.includes(this.apptsRole) &&
            this.auxRole === 'officer') ||
          this.role === 'superadmin' ||
          val.allowedRoles.includes(this.auxRole)
        )
          valid = true
      }

      return valid
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.$emit('redimensionar', this.windowSize)
    },
    async verifyUserRegistration(uid) {
      if (this.user?.uid) {
        try {
          const resp = await this.$axios.$post('/userEmailExist', { uid })
          const email = resp.user.email
          const emailValidation = email !== null && email !== ''
          const providerValidation = resp.user.providerData.length > 0
          this.userIsRegistered = emailValidation && providerValidation
        } catch {
          this.userIsRegistered = false
        }
      }
      if (this.user.public_address) {
        this.userHavePublicAddress = true
      } else {
        this.userHavePublicAddress = false
      }
    },
    logOutKeycloack() {
      if (this.isMTYInstance) {
        if (
          (this.user?.auxRole === 'citizen' || this.user?.auxRole === 'user') &&
          this.user?.role === 'user'
        ) {
          if (process.env.FB_PROJECT_ID === 'os-monterrey') {
            window.location.href = `https://iam.monterrey.gob.mx/realms/IDMty/protocol/openid-connect/logout`
          } else {
            window.location.href = `${process.env.URL_API_DOMINIO}/realms/IDMty/protocol/openid-connect/logout`
          }
        } else if (process.env.FB_PROJECT_ID === 'os-monterrey') {
          window.location.href = `https://iam.monterrey.gob.mx/realms/GobMty/protocol/openid-connect/logout`
          // window.location.href = `https://iam.monterrey.gob.mx/realms/GobMty/account/`
        } else {
          window.location.href = `${process.env.URL_API_DOMINIO}/realms/GobMty/protocol/openid-connect/logout`
        }
      } else {
        window.location.href = `https://ssotst.vicentelopez.gov.ar/realms/BUV/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(
          'https://identidaddigital.vicentelopez.gov.ar/auth/logout'
        )}`
      }
    },
    logoutIf() {
      if (
        this.$route.path !== '/complete-data' &&
        this.$route.path !== '/CompleteData'
      ) {
        if (this.$store.getters['authconfig/getsso']) {
          this.logOutKeycloack()
        } else {
          this.logout()
        }
        // this.logout()
      } else {
        this.$router.push({ path: '/' })
        this.$root.$on('logout', (valor) => {
          if (valor) {
            this.logout()
          }
        })
      }
    },
    async logout() {
      try {
        const self = this
        this.logoutLoading = true
        await logOut(this.$fire)
        const reino = localStorage.getItem('reino')
        if (reino) {
          await self.$autenticar.logout()
          localStorage.removeItem('reino')
          localStorage.removeItem('backToReino')
          localStorage.removeItem('levelUpdated')
        }
        this.$emit('close-drawer', false)
        await this.$store.commit('users/LOGOUT_USER')
        await this.$store.dispatch('users/fetchUser')
        this.$store.dispatch('setloggedIn', false)
        if (
          this.$route.path !== '/complete-data' &&
          this.$route.path !== '/CompleteData'
        ) {
          this.$router.push({ path: '/' })
        }
        this.logoutLoading = false
      } catch (error) {
        this.logoutLoading = false
        this.snackbar.show = true
        this.snackbar.text = 'Ocurrió un error al cerrar sesión'
        this.snackbar.color = 'error'
      }
    },
  },
})
