
import Address from '@/components/Map/Address.vue'
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-inspectores',
  components: { Address },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      resetMap: null,
      updateMap: null,
      switchCodeOrMap: false,
      location: {
        cp: null,
        lat: null,
        lng: null,
        city: null,
        state: null,
        street: null,
        reference: null,
        neighborhood: null,
        outdoorNumber: null,
        interiorNumber: null,
      },
    }
  },
  computed: {
    rules() {
      let rules = []
      if (this.value === null || this.value === undefined) {
        rules = [!!this.value || this.$t('required')]
      }
      return rules
    },
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    labelField() {
      if (this.obj && this.obj.schema) {
        return this.obj.schema.label || this.obj.schema.name
      }
      return ''
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.location = val
          ? {
              cp: val.cpInspectors,
              lat: val.latInspectors,
              lng: val.lngInspectors,
              city: val.cityInspectors,
              state: val.stateInspectors,
              street: val.streetInspectors,
              reference: val.referenceInspectors,
              neighborhood: val.neighborhoodInspectors,
              outdoorNumber: val.outdoorNumberInspectors,
              interiorNumber: val.interiorNumberInspectors,
            }
          : {
              cp: null,
              lat: null,
              lng: null,
              city: null,
              state: null,
              street: null,
              reference: null,
              neighborhood: null,
              outdoorNumber: null,
              interiorNumber: null,
            }
      }
    },
  },
  mounted() {
    this.switchCodeOrMap = this.value?.switchCodeOrMap || false
    this.resetMap = this.value?.resetMap || null
    this.location = this.value
      ? {
          cp: this.value.cpInspectors,
          lat: this.value.latInspectors,
          lng: this.value.lngInspectors,
          city: this.value.cityInspectors,
          state: this.value.stateInspectors,
          street: this.value.streetInspectors,
          reference: this.value.referenceInspectors,
          neighborhood: this.value.neighborhoodInspectors,
          outdoorNumber: this.value.outdoorNumberInspectors,
          interiorNumber: this.value.interiorNumberInspectors,
        }
      : {
          cp: null,
          lat: null,
          lng: null,
          city: null,
          state: null,
          street: null,
          reference: null,
          neighborhood: null,
          outdoorNumber: null,
          interiorNumber: null,
        }
  },
  methods: {
    save(location) {
      this.$emit('input', {
        ...location,
      })
    },
    handlerLocation(event) {
      this.location = Object.assign({}, event)
      // The keys in db will be with 'Inspectors'
      const location = {
        cpInspectors: event.cp,
        latInspectors: event.lat,
        lngInspectors: event.lng,
        cityInspectors: event.city,
        stateInspectors: event.state,
        streetInspectors: event.street,
        referenceInspectors: event.reference,
        neighborhoodInspectors: event.neighborhood,
        outdoorNumberInspectors: event.outdoorNumber,
        interiorNumberInspectors: event.interiorNumber,
      }
      this.save(location)
    },
  },
}
