
import { cloneDeep, startsWith } from 'lodash'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getApp } from 'firebase/app'
import firebase from 'firebase/compat/app'
import Logo from '@components/public/Logo.vue'
import AvatarMenu from '@components/public/AvatarMenu.vue'
import SearchIcon from '@components/icons/SearchIcon.vue'
import Sidebar from '@components/public/Sidebar.vue'
import algoliasearch from 'algoliasearch/lite'
import axios from 'axios'
import {
  OAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
} from 'firebase/auth'
import { updateBrand } from '@/services/Brand'
import MiniQrRegister from '@/components/Auth/MiniQrRegister.vue'
import SendEmailVerificationMixin from '@/mixins/SendEmailVerificationMixin'
import { getUser, setUserDoc } from '@/services/User'
import ConfirmLevelUpDialog from '@/components/expediente/ConfirmLevelUpDialog.vue'
import GrecaptchaMixin from '@/mixins/GrecaptchaMixin'
import AlgoliaMixin from '@/mixins/AlgoliaMixin'
import { MiError } from '@/plugins/customError.js'
import usuarios from '@/services/apis/usuarios'

declare module 'vue/types/vue' {
  interface Vue {
    $autenticar: any
  }
}

declare global {
  interface Window {
    recaptchaVerifierPasswordReset: any
    recaptchaWidgetPasswordResetId: any
    grecaptcha: any
  }
}

export default AlgoliaMixin.extend({
  name: 'Header',
  components: {
    ConfirmLevelUpDialog,
    Sidebar,
    Logo,
    AvatarMenu,
    MiniQrRegister,
    SearchIcon,
  },
  mixins: [SendEmailVerificationMixin, GrecaptchaMixin],
  props: {
    typeProfile: {
      type: String,
      default: '',
    },
    drawerSidebar: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data() {
    return {
      cardHints: false,
      projectId: process.env.FB_PROJECT_ID,
      menuSecretarias: false,
      show: false,
      walletMenu: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      isEditing: false,
      loading: false,
      header_dialog: false,
      linear_color: this.$t('editor.linear_color'),
      backgroundTypes: [
        { type: 'lineal', text: this.$t('editor.linear_color') },
        { type: 'gradient', text: this.$t('editor.gradient_color') },
      ],
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
      clipped: false,
      drawer: false,
      dialogBackAfip: false,
      model: null,
      algoliaQuery: '' as any,
      hints: [] as any,
      urlquery: '',
      algoliaAppId: process.env.ALGOLIA_APPID || '',
      algoliaSearchOnlyKey: process.env.ALGOLIA_SEARCH_ONLY_KEY || '',
      grecaptchaToken: process.env.GRECAPTCHA_TOKEN || '',
      searchActivated: false,
      icons: [] as any,
      grep: {} as any,
      isMTY:
        process.env.FB_PROJECT_ID === 'os-sandbox-mty' ||
        process.env.FB_PROJECT_ID === 'os-monterrey',
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      editedTexts: 'landingtext/landingtext',
      activeProcedures: 'procedures/getActiveProcedures',
      activeModules: 'modules/getActiveModules',
      withSso: 'authconfig/getsso',
      activePrivateProcedures: 'setting/activePrivateProcedures',
      userRole: 'users/getRole',
      userAuxRole: 'users/getAuxRole',
    }),

    ...mapState({
      modules: (state: any) => state.modules.modules,
    }),

    brandSettings(): any {
      return cloneDeep(this.brand)
    },
    cssVars(): any {
      return { '--header_color': this.brandSettings.header_color }
    },
    header_background(): string {
      return this.brandSettings.header_background_type === 'lineal'
        ? `background-color: ${this.brandSettings.header_bg_primary}`
        : this.brandSettings.header_background_type === 'gradient'
        ? `background-image: linear-gradient(to right, ${this.brandSettings.header_bg_primary}, ${this.brandSettings.header_bg_secondary}`
        : 'background-image: linear-gradient(to right, #0096da, #035d98)'
    },
    ventanillaDigital(): any {
      const ventanillaDigital = this.modules.find(
        (module: any) => module.id === 'ventanillaDigital'
      )
      return ventanillaDigital || { active: false }
    },
    isInRegister(): any {
      return (
        startsWith(this.$route.path, '/auth/completeData') ||
        this.$route.path === '/auth/linkWallet'
      )
    },
    userUsable(): any {
      return cloneDeep(this.user)
    },
  },
  watch: {
    algoliaQuery(newVal) {
      this.searchAlgolia(newVal)
    },
  },
  beforeMount() {
    const backToReino = localStorage.getItem('backToReino')
    const reino = localStorage.getItem('reino')
    if (reino === 'afip' && backToReino) {
      localStorage.removeItem('backToReino')
      this.dialogBackAfip = true
    }
    this.setAlgoliaFacets([
      'path',
      'title',
      'active',
      'parentPath',
      'config.showOnHomePage',
      'privateProcedure',
    ])
  },
  async mounted() {
    this.onResize()
    this.searchAlgolia('')
    try {
      const response = await axios({
        method: 'get',
        url: 'https://cms.run.apismty.gob.mx/rest/v1/base/aplicacion-sistema-sitio-web/',
      })

      this.icons = response.data
    } catch (error) {
      this.icons = [
        {
          id: 9,
          titulo: 'Ciudad de la niñez',
          icono: null,
          url: 'https://ciudadninez.monterrey.gob.mx/',
          hits: -2,
          destacada: false,
          color: 'deep-purple-4',
          icono_mdi: 'emoji_people',
        },
        {
          id: 32,
          titulo: 'Acceso Joven',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/accesojoven.png',
          url: 'https://accesojoven.monterrey.gob.mx/',
          hits: 0,
          destacada: true,
          color: 'primary',
          icono_mdi: 'arrow-top-right-thick',
        },
        {
          id: 14,
          titulo: 'Archivo histórico',
          icono: null,
          url: 'https://www.monterrey.gob.mx/archivohistorico',
          hits: 0,
          destacada: true,
          color: 'indigo-5',
          icono_mdi: 'description',
        },
        {
          id: 28,
          titulo: 'Bolsa de Empleo',
          icono: null,
          url: 'https://empleo.monterrey.gob.mx',
          hits: 0,
          destacada: true,
          color: 'blue-6',
          icono_mdi: 'business',
        },
        {
          id: 10,
          titulo: 'Decidimos Monterrey',
          icono: null,
          url: 'https://decidimos.monterrey.gob.mx/',
          hits: 0,
          destacada: true,
          color: 'deep-orange-8',
          icono_mdi: 'extension',
        },
        {
          id: 19,
          titulo: 'Emprendemos',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/mty_cem_logo.png',
          url: 'https://emprendemos.monterrey.gob.mx',
          hits: 0,
          destacada: true,
          color: 'primary',
          icono_mdi: 'business',
        },
        {
          id: 2,
          titulo: 'Gacetas municipales',
          icono: null,
          url: 'https://www.monterrey.gob.mx/transparencia/Oficial/Index_Gaceta.asp',
          hits: 0,
          destacada: true,
          color: 'amber',
          icono_mdi: 'note',
        },
        {
          id: 30,
          titulo: 'Huellitas Monterrey',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/huellitas.png',
          url: 'https://huellitas.monterrey.gob.mx/',
          hits: 0,
          destacada: true,
          color: 'primary',
          icono_mdi: 'mdi-paw',
        },
        {
          id: 18,
          titulo: 'Injure',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/logo_injure_v1.webp',
          url: 'https://injure.monterrey.gob.mx',
          hits: 0,
          destacada: true,
          color: 'orange-4',
          icono_mdi: 'electric_bolt',
        },
        {
          id: 27,
          titulo: 'Módulo Digital',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/modulo_icono.png',
          url: 'https://modulodigital.monterrey.gob.mx/',
          hits: 0,
          destacada: true,
          color: 'green-12',
          icono_mdi: 'mdi-cellphone-text',
        },
        {
          id: 31,
          titulo: 'Sanamente Monterrey',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/sanamente.png',
          url: 'https://sanamente.monterrey.gob.mx/',
          hits: 0,
          destacada: true,
          color: 'primary',
          icono_mdi: 'mdi-table',
        },
        {
          id: 5,
          titulo: 'Transparencia',
          icono: null,
          url: 'https://www.monterrey.gob.mx/transparencia',
          hits: 0,
          destacada: true,
          color: 'deep-purple-6',
          icono_mdi: 'policy',
        },
        {
          id: 29,
          titulo: 'iD Digital Monterrey',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/huella_1.png',
          url: 'https://id.monterrey.gob.mx/',
          hits: 0,
          destacada: true,
          color: 'deep-purple-8',
          icono_mdi: 'mdi-fingerprint',
        },
        {
          id: 21,
          titulo: '#Turismo EsCultura',
          icono: null,
          url: 'https://turismoescultura.monterrey.gob.mx/',
          hits: 0,
          destacada: null,
          color: 'pink-8',
          icono_mdi: 'museum',
        },
        {
          id: 20,
          titulo: 'Jueces Auxiliares',
          icono: null,
          url: 'https://juecesauxiliares.monterrey.gob.mx/',
          hits: 0,
          destacada: null,
          color: 'red-12',
          icono_mdi: 'groups',
        },
        {
          id: 6,
          titulo: 'Monterrey ciudad digital',
          icono: null,
          url: 'http://mtydigital.monterrey.gob.mx',
          hits: 0,
          destacada: null,
          color: 'indigo-14',
          icono_mdi: 'location_city',
        },
        {
          id: 11,
          titulo: 'Monterrey construye',
          icono: null,
          url: 'https://construye.monterrey.gob.mx',
          hits: 0,
          destacada: null,
          color: 'blue-grey-8',
          icono_mdi: 'engineering',
        },
        {
          id: 26,
          titulo: 'Pago Predial',
          icono: null,
          url: 'https://modulodigital.monterrey.gob.mx/modules/ventanillaDigital/procedures/pagoDeImpuestoPredial',
          hits: 0,
          destacada: null,
          color: 'teal-5',
          icono_mdi: 'inventory_2',
        },
        {
          id: 8,
          titulo: 'Portal de datos abiertos',
          icono: null,
          url: 'https://datos.monterrey.gob.mx/',
          hits: 0,
          destacada: null,
          color: 'green-14',
          icono_mdi: 'assessment',
        },
        {
          id: 23,
          titulo: 'Premio SIGA',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/indigo.png',
          url: 'https://premiosiga.monterrey.gob.mx/',
          hits: 0,
          destacada: null,
          color: 'indigo-10',
          icono_mdi: 'auto_awesome',
        },
        {
          id: 16,
          titulo: 'Covid-19',
          icono: null,
          url: 'https://covid19.monterrey.gob.mx/',
          hits: 1,
          destacada: false,
          color: 'green-8',
          icono_mdi: 'coronavirus',
        },
        {
          id: 3,
          titulo: 'Desarrollo verde',
          icono: null,
          url: 'https://desarrolloverde.monterrey.gob.mx/',
          hits: 1,
          destacada: false,
          color: 'green-5',
          icono_mdi: 'eco',
        },
        {
          id: 25,
          titulo: 'Facturación',
          icono: null,
          url: 'https://servicios.adon.mx/site/portal/sfe/index.html',
          hits: 1,
          destacada: true,
          color: 'deep-orange-10',
          icono_mdi: 'article',
        },
        {
          id: 33,
          titulo: 'MIDE Monterrey',
          icono:
            'https://storage.googleapis.com/mty-webspa-cms-media/base/aplicacion_sistema_sitio_web/icono/iconos-05.png',
          url: 'https://mide.monterrey.gob.mx/#/',
          hits: 1,
          destacada: true,
          color: 'blue-10',
          icono_mdi: 'mdi-poll',
        },
      ]
    }
    if (this.$store.getters['authconfig/getsso'] && this.$route.path === '/') {
      if (!this.$store.state?.grecaptcha?.enterprise) {
        await this.setGrecaptcha()
      }
      await this.getRedirect()
    }
  },
  methods: {
    setGrecaptcha(): any {
      try {
        setTimeout(() => {
          const recaptchaScript = document.createElement('script')
          if (process.env.GRECAPTCHA_TOKEN) {
            recaptchaScript.setAttribute(
              'src',
              `https://www.google.com/recaptcha/enterprise.js?render=${process.env.GRECAPTCHA_TOKEN}`
            )
            recaptchaScript.setAttribute('async', 'true')
            recaptchaScript.setAttribute('defer', 'true')
            document.head.appendChild(recaptchaScript)
            recaptchaScript.onload = () => {
              window.grecaptcha?.enterprise?.ready(async () => {
                if (window?.grecaptcha || window?.grecaptcha?.render) {
                  this.$store.commit('setReCaptcha', window.grecaptcha)
                  this.grep = await Object.assign({}, window.grecaptcha)
                } else {
                  // eslint-disable-next-line no-console
                  console.error('grecaptcha error interno')
                  // eslint-disable-next-line no-console
                  console.log('grecaptcha error interno')
                  throw new Error('Error de no encontrar el grecaptcha')
                }
              })
            }
          } else {
            // eslint-disable-next-line no-console
            console.error('grecaptcha error no encontró ENV')
            throw new Error('No trajo el env y no mandó grecaptcha')
          }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    generateGrecaptchaToken(): any {
      if (this.grep?.execute) {
        return this.grep
          .execute(this.grecaptchaToken, {
            action: 'verify',
          })
          .then((token: string) => {
            // eslint-disable-next-line no-console
            return token
          })
          .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.error('error', error)
            return error
          })
      }
    },
    async signInSSOMTY() {
      let provider: any
      if (this.projectId === 'os-monterrey') {
        provider = new OAuthProvider(
          'oidc.idmty.modulodigital.monterrey.gob.mx'
        )
      } else {
        provider = new OAuthProvider('oidc.id.mty-sandbox.os.city')
      }
      const auth = getAuth(getApp())
      if (window.location.hostname === 'localhost') {
        await signInWithPopup(auth, provider)
          .then(async (result: any) => {
            console.log('result', result)
            await this.getRedirectPopUp(result)
          })
          .catch((error: any) => {
            console.log(error)
            throw error
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        await signInWithRedirect(auth, provider)
      }
      // await signInWithRedirect(auth, provider)
    },
    async getRedirect() {
      let isCitizenFlow = true
      await firebase
        .auth()
        .getRedirectResult()
        .then(async (result: any) => {
          this.loading = true
          /* If credential exists means the user came from keycloack prepared to be used */
          if (result.credential) {
            /* User AccessToken to auth requests to MTY */
            const userToken = (result.user as any)?._delegate?.accessToken || ''
            localStorage.setItem('mtyuserToken', userToken)
            this.$store.commit('users/SET_TOKEN', userToken)
            /* Prepare general data From Keycloack */
            let userDataFromKeycloack: any = {
              email:
                result?.user?.email ||
                result?.additionalUserInfo?.profile?.email ||
                '',
              first_name: result?.additionalUserInfo?.profile?.given_name || '',
              last_name: result?.additionalUserInfo?.profile?.family_name || '',
              uid: result?.user?.uid || '',
            }
            /* Retrieve user data from Firestore */
            const { uid } = userDataFromKeycloack
            const userDataFromFirebase =
              (await getUser(this.$fire, uid)).data() || {}
            /* Validate which flow to follow, validations include check firestore if all info there is updated */
            let userDataFromMTYAPI: any = {}
            let isDBupdated: boolean = false
            if (
              result.credential?.providerId ===
                'oidc.gob.mty-sandbox.os.city' ||
              result.credential?.providerId ===
                'oidc.gobmty.modulodigital.monterrey.gob.mx'
            ) {
              this.userUsable.auxRole = this.user.auxRole
              userDataFromKeycloack = {
                ...userDataFromKeycloack,
                availableModules: userDataFromFirebase?.availableModules || [],
                role: userDataFromFirebase?.role || 'operator',
                auxRole:
                  userDataFromFirebase?.auxRole ||
                  this.userUsable.auxRole ||
                  'user',
              }
              isCitizenFlow = false
              /* Validate if info in Firestore is exactly the same as in data retrieved from Mty APIS
                if isn't the same proceed to update firestore info
              */
              isDBupdated = this.verifyToUpdateDB(
                userDataFromKeycloack,
                userDataFromFirebase,
                isCitizenFlow
              )
            } else {
              /* First make a request to /users/user to retrieve the uid from the whole realm (openID-Keycloack) after that make a request to /perfiles/perfil-basico/ with that uid to obtain the whole set of data from a citizen */
              userDataFromKeycloack = {
                ...userDataFromKeycloack,
                auxRole: 'citizen',
                role: this.user.role || 'user',
              }
              let perfilMTYData: any = {}
              try {
                await usuarios({
                  url: 'rest/v1/users/user/',
                  method: 'GET',
                })
                  .then(async (userInfoMty: any) => {
                    perfilMTYData = userInfoMty?.results[0]
                    await usuarios({
                      url: `rest/v1/perfiles/perfil-basico/${userInfoMty?.results[0].username}/`,
                      method: 'GET',
                    })
                      .then((usuarioMty: any) => {
                        perfilMTYData = { ...perfilMTYData, ...usuarioMty }
                      })
                      .catch((error: any) => {
                        // eslint-disable-next-line no-console
                        console.error('error interno')
                        // eslint-disable-next-line no-console
                        console.error(error?.message)
                        // eslint-disable-next-line no-console
                        console.error(error?.response)
                        throw new MiError('Error en perfil básico', {
                          cause: error.message,
                        })
                      })
                  })
                  .catch((error: any) => {
                    // eslint-disable-next-line no-console
                    console.error('error')
                    // eslint-disable-next-line no-console
                    console.error(error?.message)
                    // eslint-disable-next-line no-console
                    console.error(error?.response)
                    throw new MiError('Error en /users/user', {
                      cause: error.message,
                    })
                  })
                /* Break down the info into correspondent keys of information */
                userDataFromMTYAPI = {
                  email: perfilMTYData.email || '',
                  first_name: perfilMTYData.nombre || '',
                  last_name: perfilMTYData.primer_apellido || '',
                  last_name2: perfilMTYData.segundo_apellido || '',
                  birthday: perfilMTYData.fecha_nacimiento || '',
                  genero: perfilMTYData.genero || '',
                  telphone: perfilMTYData.telefono || '',
                  direccion: perfilMTYData.direccion || '',
                  cp: perfilMTYData.codigo_postal || '',
                  calle: perfilMTYData.calle || '',
                  colonia: perfilMTYData.colonia || '',
                  municipio: perfilMTYData.municipio || '',
                  estado: perfilMTYData.estado || '',
                  num_ext: perfilMTYData.numero_exterior || '',
                  num_int: perfilMTYData.numero_interior || '',
                  fotografia: perfilMTYData.fotografia || '',
                  curp: perfilMTYData.curp || '',
                  biografia: perfilMTYData.biografia || '',
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
              }
              /* Validate if info in Firestore is exactly the same as in data retrieved from Mty APIS
                if isn't the same proceed to update firestore info
              */
              isDBupdated = this.verifyToUpdateDB(
                userDataFromMTYAPI,
                userDataFromFirebase,
                isCitizenFlow
              )
            }
            let dataUser: any = {}
            dataUser = {
              ...userDataFromKeycloack,
              ...userDataFromFirebase,
              ...userDataFromMTYAPI,
              activated: true,
              totalDocumentsFinals:
                userDataFromFirebase?.totalDocumentsFinals || 0,
              tipoPersona: 'f',
              autenticated: 'mty',
              email:
                userDataFromMTYAPI?.email ||
                userDataFromFirebase?.email ||
                userDataFromKeycloack?.email,
              first_name:
                userDataFromMTYAPI?.first_name ||
                userDataFromFirebase?.first_name ||
                userDataFromKeycloack?.first_name,
              last_name:
                userDataFromMTYAPI?.last_name ||
                userDataFromFirebase?.last_name ||
                userDataFromKeycloack?.last_name,
              last_name2:
                userDataFromMTYAPI?.last_name2 ||
                userDataFromFirebase?.last_name2 ||
                '',
              created:
                userDataFromFirebase?.created ||
                firebase.firestore.Timestamp.now(),
              public_address: userDataFromFirebase?.public_address || '',
              emailVerified: result?.user?.emailVerified,
              uid: userDataFromFirebase?.uid || userDataFromKeycloack?.uid,
            }
            if (!isDBupdated) {
              await setUserDoc(this.$fire, uid, {
                ...dataUser,
              })
              if (isCitizenFlow) {
                await this.$axios
                  .$post('/users-addCitizen', {
                    uid,
                  })
                  .then(async () => {
                    await getAuth().currentUser?.getIdToken(true)
                  })
              }
            }
            this.$store.commit('users/SET_USER', dataUser)
          }
        })
        .catch((error) => {
          console.log(error)
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getRedirectPopUp(result: any) {
      console.log('holi')
      console.log(result)
      let isCitizenFlow = true
      try {
        this.loading = true
        /* If credential exists means the user came from keycloack prepared to be used */
        if (result) {
          console.log('result.credential')
          console.log(result)
          /* User AccessToken to auth requests to MTY */
          const userToken = (result.user as any)?.accessToken || ''
          localStorage.setItem('mtyuserToken', userToken)
          this.$store.commit('users/SET_TOKEN', userToken)
          /* Prepare general data From Keycloack */
          let userDataFromKeycloack: any = {
            email:
              result?.user?.email || result?.user?.providerData[0]?.email || '',
            // first_name: result?.additionalUserInfo?.profile?.given_name || '',
            // last_name: result?.additionalUserInfo?.profile?.family_name || '',
            uid: result?.user?.uid || '',
          }
          /* Retrieve user data from Firestore */
          const { uid } = userDataFromKeycloack
          const userDataFromFirebase =
            (await getUser(this.$fire, uid)).data() || {}
          /* Validate which flow to follow, validations include check firestore if all info there is updated */
          console.log('userDataFromFirebase')
          console.log(userDataFromFirebase)
          let userDataFromMTYAPI: any = {}
          let isDBupdated: boolean = false
          if (
            result?.providerId === 'oidc.gob.mty-sandbox.os.city' ||
            result?.providerId === 'oidc.gobmty.modulodigital.monterrey.gob.mx'
          ) {
            this.userUsable.auxRole = this.user.auxRole
            userDataFromKeycloack = {
              ...userDataFromKeycloack,
              availableModules: userDataFromFirebase?.availableModules || [],
              role: userDataFromFirebase?.role || 'operator',
              auxRole:
                userDataFromFirebase?.auxRole ||
                this.userUsable.auxRole ||
                'user',
            }
            isCitizenFlow = false
            /* Validate if info in Firestore is exactly the same as in data retrieved from Mty APIS
          if isn't the same proceed to update firestore info
        */
            isDBupdated = this.verifyToUpdateDB(
              userDataFromKeycloack,
              userDataFromFirebase,
              isCitizenFlow
            )
          } else {
            /* First make a request to /users/user to retrieve the uid from the whole realm (openID-Keycloack) after that make a request to /perfiles/perfil-basico/ with that uid to obtain the whole set of data from a citizen */
            userDataFromKeycloack = {
              ...userDataFromKeycloack,
              auxRole: 'citizen',
              role: this.user.role || 'user',
            }
            let perfilMTYData: any = {}
            try {
              await usuarios({
                url: 'rest/v1/users/user/',
                method: 'GET',
              })
                .then(async (userInfoMty: any) => {
                  console.log('userInfoMty')
                  console.log(userInfoMty)
                  perfilMTYData = userInfoMty?.results[0]
                  console.log('perfilMTYData')
                  console.log(perfilMTYData)
                  await usuarios({
                    url: `rest/v1/perfiles/perfil-basico/${userInfoMty?.results[0].username}/`,
                    method: 'GET',
                  })
                    .then((usuarioMty: any) => {
                      console.log('usuarioMty')
                      console.log(usuarioMty)
                      perfilMTYData = { ...perfilMTYData, ...usuarioMty }
                    })
                    .catch((error: any) => {
                      console.error('llegue aquí')
                      // eslint-disable-next-line no-console
                      console.error('error interno')
                      // eslint-disable-next-line no-console
                      console.error(error?.message)
                      // eslint-disable-next-line no-console
                      console.error(error?.response)
                      throw new MiError('Error en perfil básico', {
                        cause: error.message,
                      })
                    })
                })
                .catch((error: any) => {
                  console.error('llegue acá')
                  // eslint-disable-next-line no-console
                  console.error('error')
                  // eslint-disable-next-line no-console
                  console.error(error?.message)
                  // eslint-disable-next-line no-console
                  console.error(error?.response)
                  throw new MiError('Error en /users/user', {
                    cause: error.message,
                  })
                })
              /* Break down the info into correspondent keys of information */
              userDataFromMTYAPI = {
                email: perfilMTYData.email || '',
                first_name: perfilMTYData.nombre || '',
                last_name: perfilMTYData.primer_apellido || '',
                last_name2: perfilMTYData.segundo_apellido || '',
                birthday: perfilMTYData.fecha_nacimiento || '',
                genero: perfilMTYData.genero || '',
                telphone: perfilMTYData.telefono || '',
                direccion: perfilMTYData.direccion || '',
                cp: perfilMTYData.codigo_postal || '',
                calle: perfilMTYData.calle || '',
                colonia: perfilMTYData.colonia || '',
                municipio: perfilMTYData.municipio || '',
                estado: perfilMTYData.estado || '',
                num_ext: perfilMTYData.numero_exterior || '',
                num_int: perfilMTYData.numero_interior || '',
                fotografia: perfilMTYData.fotografia || '',
                curp: perfilMTYData.curp || '',
                biografia: perfilMTYData.biografia || '',
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error)
            }
            /* Validate if info in Firestore is exactly the same as in data retrieved from Mty APIS
          if isn't the same proceed to update firestore info
        */
            isDBupdated = this.verifyToUpdateDB(
              userDataFromMTYAPI,
              userDataFromFirebase,
              isCitizenFlow
            )
          }
          let dataUser: any = {}
          dataUser = {
            ...userDataFromKeycloack,
            ...userDataFromFirebase,
            ...userDataFromMTYAPI,
            activated: true,
            totalDocumentsFinals:
              userDataFromFirebase?.totalDocumentsFinals || 0,
            tipoPersona: 'f',
            autenticated: 'mty',
            email:
              userDataFromMTYAPI?.email ||
              userDataFromFirebase?.email ||
              userDataFromKeycloack?.email,
            first_name:
              userDataFromMTYAPI?.first_name ||
              userDataFromFirebase?.first_name ||
              userDataFromKeycloack?.first_name,
            last_name:
              userDataFromMTYAPI?.last_name ||
              userDataFromFirebase?.last_name ||
              userDataFromKeycloack?.last_name,
            last_name2:
              userDataFromMTYAPI?.last_name2 ||
              userDataFromFirebase?.last_name2 ||
              '',
            created:
              userDataFromFirebase?.created ||
              firebase.firestore.Timestamp.now(),
            public_address: userDataFromFirebase?.public_address || '',
            emailVerified: result?.user?.emailVerified,
            uid: userDataFromFirebase?.uid || userDataFromKeycloack?.uid,
          }
          if (!isDBupdated) {
            await setUserDoc(this.$fire, uid, {
              ...dataUser,
            })
            if (isCitizenFlow) {
              await this.$axios
                .$post('/users-addCitizen', {
                  uid,
                })
                .then(async () => {
                  await getAuth().currentUser?.getIdToken(true)
                })
            }
          }
          this.$store.commit('users/SET_USER', dataUser)
        }
      } catch (error) {
        console.error(error)
      }
    },
    verifyToUpdateDB(
      dataFromMty: any,
      dataFromFb: any,
      isCitizenFlow: boolean
    ) {
      const dataFromMtyUsable = cloneDeep(dataFromMty)
      let isUpdated: any = true
      if (!isCitizenFlow) {
        delete dataFromMtyUsable?.uid
        const hasAvailableModules =
          dataFromFb && Object.keys(dataFromFb).includes('availableModules')
        Object.keys(dataFromMtyUsable).forEach((key: any) => {
          if (
            (dataFromFb && !Object.keys(dataFromFb).includes(key)) ||
            dataFromMty?.[key] !== dataFromFb?.[key] ||
            !Object.keys(dataFromFb).includes('auxRole') ||
            !Object.keys(dataFromFb).includes('role')
          ) {
            isUpdated = false
          }
        })
        return hasAvailableModules && isUpdated
      } else {
        Object.keys(dataFromMty).forEach((key: any) => {
          if (
            !Object.keys(dataFromFb).includes(key) ||
            dataFromMty?.[key] !== dataFromFb?.[key]
          ) {
            isUpdated = false
          }
        })
        return isUpdated
      }
    },
    cerrar() {
      this.drawer = false
    },
    toggleEditor() {
      this.$store.dispatch('ui/toggleEditor')
    },
    copyAdressToClipBoard() {
      navigator.clipboard.writeText(this.user.public_address)
    },
    getActiveSubmodules(submodules: any) {
      return submodules?.filter((submodule: any) => submodule.active === true)
    },
    ...mapMutations({
      setBrandSettings: 'brand/SET_BRAND',
    }),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    autenticar(reino: string) {
      this.$autenticar.init(reino)
    },
    async updateBrand() {
      this.loading = true
      try {
        await updateBrand(this.$fire, this.brandSettings.id, this.brandSettings)
        this.setBrandSettings(this.brandSettings)
      } catch {
        this.snackbar.show = true
        this.snackbar.text = 'No se pudo guardar la actualización'
        this.snackbar.color = 'error'
      }
      this.loading = false
      this.isEditing = false
      this.header_dialog = false
    },
    onFileSelected(event: any) {
      this.loading = true
      const self = this
      const storage = this.$fire.storage
      const target: string = event.target.id
      const file = event.target.files[0]
      const photosRef = storage.ref().child(`brand/images/${target}`)
      photosRef
        .put(file)
        .then((snapshot: any) => {
          snapshot.ref.getDownloadURL().then(function (downloadURL: string) {
            ;(self as any).brandSettings[target] = downloadURL
          })
        })
        .catch((err: any) => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
        })
      this.loading = false
      this.isEditing = false
    },
    restoreDefaultLogo() {
      this.brandSettings.logo =
        'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2F4010fc67-09a4-41a8-8851-29fc2a45d6bb?alt=media&token=984cee1d-2955-4231-807d-21dfa45b8746'
    },

    goToProceduresListPage() {
      this.$router.push({
        path: `/digital-services?home=true&search=${
          this.algoliaQuery ? this.algoliaQuery : ''
        }`,
      })
      this.algoliaQuery = ''
      this.hints = false
      this.searchActivated = false
    },
    goToSpecificProcedure(path: any) {
      this.$router.push({ path })
    },
    searchAlgolia(query: any) {
      const algoliaClient = algoliasearch(
        this.algoliaAppId,
        this.algoliaSearchOnlyKey
      )
      const index = algoliaClient.initIndex('fichas')
      let filters = `active: true`
      if (
        !this.activePrivateProcedures ||
        (['', 'user'].includes(this.userRole) && this.userAuxRole === 'citizen')
      ) {
        filters += ` AND privateProcedure: false`
      }
      index
        .search(query, {
          filters,
          hitsPerPage: 200,
        })
        .then(({ hits }) => {
          if (hits && this.algoliaQuery?.length > 2) {
            this.cardHints = true
            this.hints = hits
          } else {
            this.cardHints = false
          }
        })
    },
    searchPath() {
      const path =
        this.algoliaQuery &&
        this.algoliaQuery.configPath &&
        this.algoliaQuery.configPath.url &&
        this.algoliaQuery.configPath.url.length > 2
          ? this.algoliaQuery.configPath.url
          : this.algoliaQuery.path

      this.goToSpecificProcedure(path)
    },
  },
})
