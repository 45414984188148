import { render, staticRenderFns } from "./SidebarFuncionario.vue?vue&type=template&id=ae3e4882&scoped=true&"
import script from "./SidebarFuncionario.vue?vue&type=script&lang=js&"
export * from "./SidebarFuncionario.vue?vue&type=script&lang=js&"
import style0 from "./SidebarFuncionario.vue?vue&type=style&index=0&id=ae3e4882&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae3e4882",
  null
  
)

export default component.exports