import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
} from 'vuetify/lib'
import minifyTheme from 'minify-css-string'
import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives'
import Vue from 'vue'
import DocumentIcon from '@/components/icons/oscity-documentos.vue'
import GobiernoIcon from '@/components/icons/oscity-gobierno.vue'
import IdentidadIcon from '@/components/icons/oscity-identidad.vue'
import AutenticarDni from '@/components/icons/autenticar-dni.vue'
import AutenticarAnses from '@/components/icons/autenticar-anses.vue'
import AutenticarAfip from '@/components/icons/autenticar-afip.vue'
import AutenticarMa from '@/components/icons/autenticar-ma.vue'
import IdCardIcon from '@/components/icons/procedures/id-card.vue'
import OpenBookIcon from '@/components/icons/procedures/open-book.vue'
import PrivateProcedure from '~/components/icons/inbox/PrivateProcedure.vue'

Vue.use(Vuetify, {
  components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect },
  directives: { Ripple, Intersect, Touch, Resize },
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ app }) => {
  const theme = process.env.THEME || 'default'
  return {
    defaultAssets: {
      font: {
        family: 'Lato',
      },
    },
    customVariables:
      theme === 'Monterrey'
        ? ['@/assets/variablesMonterrey.scss']
        : ['@/assets/variables.scss'],

    lang: {
      locales: { en, es, 'es-AR': es, 'es-MTY': es, 'es-NL': es },
      current: 'es',
    },
    theme: {
      dark: false,
      themes: {
        light: {
          primary: '#061E47',
          secondary: '#053F6F',
          accent: '#2196F3',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      options: {
        minifyTheme,
        variations: false,
        customProperties: true,
      },
    },
    icons: {
      values: {
        oscity_id_card: {
          component: IdCardIcon,
        },
        oscity_open_book: {
          component: OpenBookIcon,
        },
        oscity_identidad: {
          component: IdentidadIcon,
        },
        oscity_gobierno: {
          component: GobiernoIcon,
        },
        oscity_documentos: {
          component: DocumentIcon,
        },
        oscity_autenticar_dni: {
          component: AutenticarDni,
        },
        oscity_autenticar_anses: {
          component: AutenticarAnses,
        },
        oscity_autenticar_afip: {
          component: AutenticarAfip,
        },
        oscity_autenticar_mia: {
          component: AutenticarMa,
        },
        oscity_private_procedure: {
          component: PrivateProcedure,
        },
      },
    },
  }
}
