import { cloneDeep } from 'lodash'
import {
  getConfiguration,
  getAllInspectorsConcepts,
  getActivateInspectorsConcepts,
} from '@/services/Inspectors'

export const state = () => ({
  inspectorsConcepts: [],
  inspectorsConfiguration: {
    filesSize: 3,
    anonymousInspection: false,
  },
})

export const mutations = {
  SET_INSPECTORS_CONCEPTS(state, inspectorsConcepts) {
    state.inspectorsConcepts = cloneDeep(inspectorsConcepts)
  },
  SET_INSPECTORS_CONFIG(state, inspectorsConfiguration) {
    state.inspectorsConfiguration = Object.assign({}, inspectorsConfiguration)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllInspectorsConcepts(this.$fire)
      const inspectorsConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          inspectorsConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_INSPECTORS_CONCEPTS', inspectorsConcepts)
    } catch (error) {
      // @todo Handle Error
      console.log(error)
      throw new Error(error)
    }
  },
  async getActive({ commit }) {
    try {
      const result = await getActivateInspectorsConcepts(this.$fire)
      const inspectorsConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          inspectorsConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_INSPECTORS_CONCEPTS', inspectorsConcepts)
    } catch (error) {
      // @todo Handle Error
      console.log(error)
      throw new Error(error)
    }
  },
  async getConfiguration({ commit }) {
    try {
      const result = await getConfiguration(this.$fire)
      let inspectorsConfiguration = {
        filesSize: 3,
        anonymousInspection: false,
      }
      if (!result.empty) {
        const data = result.docs[0].data()
        inspectorsConfiguration = Object.assign(
          {},
          {
            ...data,
            id: data.id,
          }
        )
      }
      commit('SET_INSPECTORS_CONFIG', inspectorsConfiguration)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
  setConfiguration({ commit }, inspectorsConfiguration) {
    commit('SET_INSPECTORS_CONFIG', inspectorsConfiguration)
  },
}

export const getters = {
  getInspectorsConcepts(state) {
    return state.inspectorsConcepts
  },
  getActiveInspectorsConcepts(state) {
    const activeinspectorsConcepts = state.inspectorsConcepts.filter(
      (paymentConcept) => paymentConcept.active
    )
    return activeinspectorsConcepts
  },
  getInspectorsConfig(state) {
    return state.inspectorsConfiguration
  },
}
