import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from 'firebase/functions'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { isTokenExpired } from '@/services/Utils'
const region = process.env.REGION || 'us-central1'

const urlUsuarios = process.env.URL_API_USUARIOS

const usuarios = async (config: any) => {
  let body = {
    type: config.method,
    url: `${urlUsuarios}/${config.url}`,
  }
  if (config.data || config.params) {
    body = Object.assign({ data: config.data || config.params }, body)
  }

  if (process.browser) {
    const userToken =
      window.$nuxt.$store.getters['users/user'].token ||
      localStorage.getItem('mtyuserToken')
    // Set Authorization token
    if (userToken) {
      const isAccesstokenExpired = isTokenExpired(userToken)
      const tokenToUse = isAccesstokenExpired
        ? await getAuth().currentUser?.getIdToken(true)
        : userToken
      // config.headers.Authorization = `Bearer ${userToken}`
      body = Object.assign({ token: tokenToUse }, body)
    }
  }

  const functions = getFunctions(getApp(), region)
  // connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  const usuarios = httpsCallable(functions, 'mty-instances-usuarios')
  return await usuarios({
    ...body,
  })
    .then((response: any) => {
      if (response.data && response.data.data) return response.data.data
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Ocurrió un error')
      throw new Error(error)
    })
}

export default usuarios
