import Vue from 'vue'
import { uniq } from 'lodash'
import { mapMutations, mapState, mapGetters } from 'vuex'
import algoliasearch from 'algoliasearch'
import { updateSettings } from '~/services/Settings'

export default Vue.extend({
  name: 'AlgoliaMixin',
  data() {
    return {
      algoliaAppId: process.env.ALGOLIA_APPID || '',
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY || '',
      isMTYInstance:
        process.env.FB_PROJECT_ID === 'os-sandbox-mty' ||
        process.env.FB_PROJECT_ID === 'os-monterrey',
    }
  },
  computed: {
    ...mapState({
      settings: (state: any) => state.setting.setting,
    }),
    ...mapGetters({
      user: 'users/user',
    }),
  },
  methods: {
    ...mapMutations({
      updateSettings_element: 'setting/UPDATE_ELEMENT',
    }),
    async setAlgoliaFacets(searchableAttributes: Array<string>) {
      if (
        this.user.uid &&
        ['superadmin', 'admin', 'editor'].includes(this.user.role)
      ) {
        let fusionfactes = []
        let attributesForSearchSandbox: string[] = []
        if (this.settings?.algoliaFacets?.length > 0) {
          fusionfactes = [
            ...this.settings.algoliaFacets,
            ...searchableAttributes,
          ]
        } else {
          fusionfactes = [...searchableAttributes]
        }
        fusionfactes = uniq(fusionfactes)
        if (
          this.isMTYInstance &&
          process.env.FB_PROJECT_ID === 'os-sandbox-mty'
        ) {
          attributesForSearchSandbox = [
            'path',
            'title',
            'active',
            'parentPath',
            'config.showOnHomePage',
            'privateProcedure',
          ]
        }
        this.updateSettings_element({
          element: 'algoliaFacets',
          data: fusionfactes,
        })
        await updateSettings(this.$fire, this.settings.id, { ...this.settings })

        const algoliaClientFacets = algoliasearch(
          this.algoliaAppId,
          this.algoliaAdminKey
        )
        const indexFacets = algoliaClientFacets.initIndex('fichas')
        if (
          this.isMTYInstance &&
          process.env.FB_PROJECT_ID === 'os-sandbox-mty'
        ) {
          indexFacets.setSettings(
            {
              searchableAttributes: attributesForSearchSandbox,
              ranking: ['asc(title)'],
            },
            {
              attributesForFaceting: fusionfactes,
            }
          )
        } else {
          indexFacets.setSettings(
            {
              searchableAttributes: fusionfactes,
              ranking: ['asc(title)'],
            },
            {
              attributesForFaceting: fusionfactes,
            }
          )
        }
      }
    },
  },
})
