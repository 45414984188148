export const getConfiguration = async ($fire: any) => {
  return await $fire.firestore
    .collection('tools')
    .doc('preventions')
    .collection('configuration')
    .get()
}
export const getAllInspectorsConcepts = async ($fire: any) => {
  return await $fire.firestore
    .collection('tools')
    .doc('preventions')
    .collection('concepts')
    .get()
}
export const getActivateInspectorsConcepts = async ($fire: any) => {
  const concepts = [] as any
  await $fire.firestore
    .collection('tools')
    .doc('preventions')
    .collection('concepts')
    .where('active', '==', true)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(function (doc: any) {
        concepts.push({
          ...doc.data(),
          id: doc.id,
        })
      })
    })
  return concepts
}

export const deleteInspectorsConcept = async ($fire: any, id: string) => {
  return await $fire.firestore
    .collection('tools')
    .doc('preventions')
    .collection('concepts')
    .doc(id)
    .delete()
}
