
import * as _ from 'lodash'

import { Editor, EditorContent } from '@tiptap/vue-2'
// eslint-disable-next-line import/no-named-as-default
import StarterKit from '@tiptap/starter-kit'
import { Link } from '@tiptap/extension-link'
import { Heading } from '@tiptap/extension-heading'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import { CharacterCount } from '@tiptap/extension-character-count'

import { mergeAttributes } from '@tiptap/core'
import MenuBar from './MenuBar.vue'

export default {
  name: 'TextEditor',
  components: {
    EditorContent,
    MenuBar,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    onlyRead: {
      type: Boolean,
      default: false,
    },
    headingClasses: {
      type: Object,
      default() {
        return {
          h1: 'editor_title',
          h2: 'editor_subtitle',
        }
      },
    },
    paragraphClass: {
      type: String,
      default: 'editor_paragraph font-weight-regular',
    },
    miniMenu: {
      type: Boolean,
      default: false,
    },
    characterLimit: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      editor: null,
      openMenuBar: true,
    }
  },

  computed: {
    menuVisible() {
      return this.miniMenu ? !this.onlyRead && this.openMenuBar : !this.onlyRead
    },
    totalCharacters() {
      let totalCharacters = 0
      if (this.characterLimit) {
        totalCharacters = this.editor?.storage?.characterCount?.characters()
      }
      return totalCharacters
    },
  },

  watch: {
    totalCharacters(val) {
      this.$emit('totalCharacters', val)
    },
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value)
    },
  },

  mounted() {
    const self = this
    const extraExtensions = []
    if (this.characterLimit > 0) {
      extraExtensions.push(
        CharacterCount.configure({
          limit: this.characterLimit,
        })
      )
    }
    this.editor = new Editor({
      editable: !self.onlyRead,
      content: self.value,
      onUpdate({ editor }) {
        _.debounce(() => {
          self.$emit('update', editor.getHTML())
        }, 500)()
      },
      // onFocus() {
      //   self.openMenuBar = true
      // },
      // onBlur() {
      //   self.openMenuBar = false
      // },
      extensions: [
        StarterKit.configure({
          heading: false,
          placeholder: false,
          paragraph: false,
        }),
        Heading.configure({
          levels: [1, 2],
        }).extend({
          renderHTML({ node, HTMLAttributes }) {
            const hasLevel = this.options.levels.includes(node.attrs.level)
            const level = hasLevel ? node.attrs.level : this.options.levels[0]
            return [
              `h${level}`,
              mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: `${self.headingClasses[`h${level}`]}`,
              }),
              0,
            ]
          },
        }),
        Paragraph.configure({
          HTMLAttributes: {
            class: self.paragraphClass,
          },
        }),

        Placeholder.configure({
          placeholder: self.onlyRead
            ? `[${self.$t('editor.edit_text')}]`
            : self.$t('editor.edit_text'),
        }),
        Link,
        ...extraExtensions,
      ],
    })
  },

  beforeDestroy() {
    this.editor && this.editor.destroy()
  },
}
