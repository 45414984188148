import { cloneDeep } from 'lodash'
import {
  getConfiguration,
  getAllPreventionsConcepts,
  getActivatePreventionsConcepts,
} from '@/services/Preventions'

export const state = () => ({
  preventionsConcepts: [],
  preventionsConfiguration: {
    filesSize: 3,
  },
})

export const mutations = {
  SET_PREVENTIONS_CONCEPTS(state, preventionsConcepts) {
    state.preventionsConcepts = cloneDeep(preventionsConcepts)
  },
  SET_PREVENTIONS_CONFIG(state, preventionsConfiguration) {
    state.preventionsConfiguration = Object.assign({}, preventionsConfiguration)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllPreventionsConcepts(this.$fire)
      const preventionsConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          preventionsConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_PREVENTIONS_CONCEPTS', preventionsConcepts)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
  async getActive({ commit }) {
    try {
      const result = await getActivatePreventionsConcepts(this.$fire)
      const preventionsConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          preventionsConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_PREVENTIONS_CONCEPTS', preventionsConcepts)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
  async getConfiguration({ commit }) {
    try {
      const result = await getConfiguration(this.$fire)
      let preventionsConfiguration = {
        filesSize: 3,
      }
      console.log('getConfiguration', result)
      if (!result.empty) {
        const data = result.docs[0].data()
        preventionsConfiguration = Object.assign(
          {},
          {
            ...data,
            id: data.id,
          }
        )
      }
      commit('SET_PREVENTIONS_CONFIG', preventionsConfiguration)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
  setConfiguration({ commit }, preventionsConfiguration) {
    commit('SET_PREVENTIONS_CONFIG', preventionsConfiguration)
  },
}

export const getters = {
  getPreventionsConcepts(state) {
    return state.preventionsConcepts
  },
  getActivePreventionsConcepts(state) {
    const activepreventionsConcepts = state.preventionsConcepts.filter(
      (paymentConcept) => paymentConcept.active
    )
    return activepreventionsConcepts
  },
  getPreventionsConfig(state) {
    return state.preventionsConfiguration
  },
}
